<template>
  <div class="login-page">
    <div v-if="!$system.user.isAuthenticated" class="container">
      <template v-if="!resetPasswordShow">
        <div class="row login">
          <b-form class="p-4 mt-5 mb-5 login__form" @submit.prevent="loginUser">
            <b-spinner v-if="loading" variant="dark"></b-spinner>
            <h3 class="mb-4 mt-2">Authorization</h3>
            <label class="mb-3">
              Email:*
              <b-input
                type="email"
                required
                v-model="user.Email"
                :disabled="activeInput"
                placeholder="Enter your email..."
              />
            </label>
            <label class="mb-1">
              Password:*
              <b-input
                type="password"
                required
                v-model="user.Password"
                :disabled="activeInput"
                placeholder="Enter your password..."
              />
            </label>
            <a class="mb-3" @click="resetPasswordShow = true">
              Reset my password
            </a>
            <button class="btn btn-main" type="submit" :disabled="validForm">
              Submit
            </button>
          </b-form>
        </div>
      </template>

      <template v-else>
        <div class="row login">
          <b-form
            class="p-4 mt-5 mb-5 login__form"
            @submit.prevent="resetPassword"
          >
            <b-spinner v-if="loading" variant="dark"></b-spinner>
            <h3 class="mb-4 mt-2">Reset password</h3>
            <label class="mb-3">
              Email:
              <b-input
                type="email"
                required
                v-model="resetFormEmail"
                :disabled="activeInputReset"
                placeholder="Enter your email..."
              />
            </label>
            <a class="mb-3" @click="resetPasswordShow = false">
              Return to login
            </a>
            <button
              class="btn btn-main"
              type="submit"
              :disabled="validFormReset || activeInputReset"
            >
              Reset
            </button>
          </b-form>
        </div>
      </template>
    </div>
    <div v-else class="container">
      <div class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import './LoginPage.scss'
import AuthService from '@/services/auth/auth.service'
export default {
  name: 'LoginPage',
  metaInfo: {
    title: 'Login',
    titleTemplate: '%s | Merchant Portal | Nexi ',
  },
  data() {
    return {
      loading: false,
      activeInput: false,
      activeInputReset: false,
      resetPasswordShow: false,
      resetFormEmail: '',
      user: {
        Email: '',
        Password: '',
      },
    }
  },
  mounted() {
    if (this.$system.user.isAuthenticated) {
      this.$router.push('/')
    }
  },
  computed: {
    validForm() {
      if (this.user.Password && this.user.Email) {
        return false
      } else return 'disabled'
    },
    validFormReset() {
      if (this.resetFormEmail) {
        return false
      } else return 'disabled'
    },
  },
  methods: {
    async loginUser() {
      try {
        if (this.user.Password && this.user.Email) {
          this.loading = true
          this.activeInput = true
          const response = await AuthService.authUser(this.user)
          this.loading = false
          if (response && response.status == 'success' && response.body) {
            this.$system.login(
              this.user,
              response.body.Id,
              response.body.FirstName,
              response.body.LastName
            )
            this.$system.makeToast(
              'success',
              'Authorization',
              'Authorization successfull!',
            )
          } else {
            this.activeInput = false
            this.$system.makeToast(
              'error',
              'Authorization',
              'Your email or password are incorrect, please try again.',
            )
          }
        }
      } catch (err) {
        this.$system.makeToast(
          'error',
          'Authorization',
          'Your email or password are incorrect, please try again.',
        )
        this.loading = false
        this.activeInput = false
      }
    },
    async resetPassword() {
      try {
        if (this.resetFormEmail) {
          this.loading = true
          this.activeInputReset = true
          let resetEmail = {
            email: this.resetFormEmail || '',
          }
          const response = await AuthService.resetPassword(resetEmail)
          this.loading = false

          if (response && response.status == 'success') {
            this.$system.makeToast(
              'success',
              'Reset password',
              'Password sent. Please check your email.',
            )
            this.resetPasswordShow = false
            this.resetFormEmail = ''
            this.activeInputReset = false
          } else {
            this.activeInputReset = false
            this.resetPasswordShow = false
          }
        }
      } catch (err) {
        this.$system.makeToast(
          'error',
          'Reset password',
          'Cannot reset password on provided account or invalid email.',
        )
        this.loading = false
        this.activeInputReset = false
        this.resetPasswordShow = false
      }
    },
  },
}
</script>
